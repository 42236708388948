import { useCallback, useEffect, useRef, useState } from 'react'

import {
  currentModelLoadedAction,
  getCurrentModelNodesById,
  mainModelLoadedAction
} from '../../../../modules/modelStore'
import {
  backgroundActiveSelector,
  setProgressAction
} from '../../../../modules/mainStore'
import useH3DStore from '../../../../modules/useH3DStore'

import { getCurrentPromotion } from '../../../../modules/promotionStore'
import { Torre1 } from './Torre1'
import { Torre2 } from './Torre2'
import { Sky } from '../Sky'
import { Jardinera } from './Jardinera'
import { Torre1Ventanas } from './Torre1Ventanas'
import HousesFBX from '../Houses/HousesFBX'
// import { FondoGoogle } from './FondoGoogle'
import Balls from './Balls'
import { TorresBox } from './Box'

export const NeinorPanoramic = ({ envMap, visible, ...props }) => {
  const [l1, setL1] = useState(false)
  const [l2, setL2] = useState(false)
  const [l3, setL3] = useState(false)
  const [l4, setL4] = useState(false)
  const [l5, setL5] = useState(false)
  const [l6, setL6] = useState(false)
  const [l7, setL7] = useState(false)

  const promotion = useH3DStore(getCurrentPromotion)
  const nodes = useH3DStore(getCurrentModelNodesById)
  const backgroundHidden = !useH3DStore(backgroundActiveSelector)
  const setProgress = useH3DStore(setProgressAction)
  const mainModelLoaded = useH3DStore(mainModelLoadedAction)
  const currentModelLoaded = useH3DStore(currentModelLoadedAction)

  const p = useRef(0)
  const pMaxStep = useRef(33)

  const timer = useRef(null)
  useEffect(() => {
    if (!timer.current) {
      // console.log('******************timer**********************')
      timer.current = setInterval(() => {
        if (p.current >= 100) {
          clearInterval(timer.current)
          timer.current = null
        }
        p.current = Math.max(pMaxStep.current, pMaxStep.current + 1)
        setProgress(p.current)
      }, 500)
    }
  }, [setProgress])

  const handleLoad0 = useCallback(() => {
    p.current = Math.max(p.current, 45)
    pMaxStep.current = 40
    setProgress(p.current)

    setL1(true)
  }, [setProgress])

  const handleLoad1 = useCallback(() => {
    p.current = Math.max(p.current, 50)
    pMaxStep.current = 60
    setProgress(p.current)

    setL2(true)
  }, [setProgress])

  const handleLoad2 = useCallback(() => {
    setProgress(100)
    clearInterval(timer.current)
    timer.current = null
    setProgress(p.current)
    mainModelLoaded()

    setL3(true)
  }, [mainModelLoaded, setProgress])

  const handleLoad3 = useCallback(() => {
    setL4(true)
  }, [])

  const handleLoad4 = useCallback(() => {
    setL5(true)
  }, [])

  const handleLoad5 = useCallback(() => {
    setL6(true)
  }, [])

  const handleLoad6 = useCallback(() => {
    // setL7(true)
    currentModelLoaded()
  }, [])

  const handleLoad7 = useCallback(() => {
    currentModelLoaded()
  }, [currentModelLoaded])

  // console.log(l1, l2, l3, l4, l5, l6)
  return promotion ? (
    <>
      {/* <TorresBox /> */}
      <Torre1
        onLoad={handleLoad0}
        nodes={nodes}
        envMap={envMap}
        backgroundHidden={backgroundHidden}
        {...props}
      />

      {l1 && (
        <Torre1Ventanas
          onLoad={handleLoad1}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l2 && (
        <Torre2
          onLoad={handleLoad2}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l3 && (
        <Sky
          src={'models/neinor_panoramic/model/fondo.jpg'}
          onLoad={handleLoad3}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l4 && <Jardinera onLoad={handleLoad4} />}
      {l5 && (
        <HousesFBX
          src="models/neinor_panoramic/model/cajas_torre_2.fbx"
          name="Cajas 2"
          onLoad={handleLoad5}
          envMap={envMap}
          withPrinex={promotion.withPrinex}
        />
      )}
      {l6 && (
        <HousesFBX
          src="models/neinor_panoramic/model/cajas_torre_1.fbx"
          name="Cajas 1"
          onLoad={handleLoad6}
          envMap={envMap}
          withPrinex={promotion.withPrinex}
        />
      )}
      {/* {l7 && <FondoGoogle onLoad={handleLoad7} />} */}

      {/* {l6 && (
        <Balls
          onLoad={handleLoad6}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )} */}
    </>
  ) : null
}
export default NeinorPanoramic
