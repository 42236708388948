import { useRef, useState } from 'react'
import useH3DStore from '../../../../modules/useH3DStore'
import {
  HOUSE_STATE_COLORS,
  getCurrentPromotion,
  setCurrentHouseByNodeIdAction
} from '../../../../modules/promotionStore'
import {
  MAIN_MODE,
  filterActiveSelector,
  setMainModeAction
} from '../../../../modules/mainStore'
import { useCursor } from '@react-three/drei'
import { useGesture } from '@use-gesture/react'

const isObjectInTop = (objectId, intersections) => {
  for (const i of intersections) {
    if (i.object.visible && !i.object.userData.boundingBox) {
      return objectId === i.object.id
    }
  }
  return false
}

export const House = ({
  node,
  position,
  rotation,
  scale,
  house,
  envMap,
  backgroundActive,
  withPrinex
}) => {
  const ref = useRef(null)
  const promotion = useH3DStore(getCurrentPromotion)
  const filterActive = useH3DStore(filterActiveSelector)

  const setCurrentHouseByNodeId = useH3DStore(setCurrentHouseByNodeIdAction)
  const setMainMode = useH3DStore(setMainModeAction)
  const [hovered, setHovered] = useState()
  const disabled = !Boolean(house) || !filterActive

  // console.log(house)
  let color = { r: 1, g: 1, b: 1 }
  let houseStateData = null
  if (promotion.venta) {
    houseStateData = HOUSE_STATE_COLORS[house?.sellState]
    color = houseStateData ? houseStateData?.color : { r: 1, g: 1, b: 1 }
  } else {
    houseStateData = HOUSE_STATE_COLORS[house?.rentState]
    color = houseStateData ? houseStateData?.color : { r: 1, g: 1, b: 1 }
  }

  useCursor(hovered && !disabled && houseStateData?.showBillboard)

  const bind = useGesture(
    {
      onMove: ({ event }) => {
        event.stopPropagation()
        if (isObjectInTop(ref.current?.id, event.intersections)) {
          setHovered(true)
        } else {
          setHovered(false)
        }
      },
      onDrag: ({ event, tap }) => {
        event.stopPropagation()
        if (tap) {
          if (
            !disabled &&
            isObjectInTop(ref.current?.id, event.intersections) &&
            houseStateData?.showBillboard
          ) {
            setCurrentHouseByNodeId(node.name)
            !withPrinex && setMainMode(MAIN_MODE.PLAN)
            event.stopPropagation()
          }
        }
      }
    },
    {
      drag: { filterTaps: true }
    }
  )

  const factor = node.name === 'Torre1-Portal1-Planta07-PuertaD' ? 1.02 : 1
  return (
    <mesh
      {...bind()}
      ref={ref}
      key={node.name}
      geometry={node.geometry}
      position={position}
      rotation={rotation}
      scale={[scale[0] * factor, scale[1] * factor, scale[2] * factor]}
      renderOrder={100}
    >
      <meshStandardMaterial
        envMap={backgroundActive ? envMap : null}
        transparent={true}
        opacity={disabled ? 0 : hovered ? 0.7 : 0.5}
        color-r={color.r}
        color-g={color.g}
        color-b={color.b}
        emissive-r={color.r}
        emissive-g={color.g}
        emissive-b={color.b}
        roughness={1}
        metalness={0}
        castShadow={false}
      />
    </mesh>
  )
}
export default House
