import { styled } from '@mui/material/styles'

import CloseButton from '../Common/CloseButton'
import Details from './Details'
import Title from './Title'
import Price from './Price'
import PlanButton from './PlanButton'
import useH3DStore from '../../modules/useH3DStore'
import { isMobile } from '../../modules/mainStore'
import {
  HOUSE_STATE_COLORS,
  getCurrentHouse,
  getCurrentPromotion,
  setCurrentHouseAction
} from '../../modules/promotionStore'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: theme.zIndex.overlay,
  boxSizing: 'border-box',
  width: mobile ? 200 : 320,
  maxWidth: '96%',
  padding: mobile ? 12 : 24,
  backgroundColor: theme.palette.primary.main
}))

export const HouseDetails = () => {
  const promotion = useH3DStore(getCurrentPromotion)
  const mobile = useH3DStore(isMobile)
  const currentHouse = useH3DStore(getCurrentHouse)
  const setCurrentHouse = useH3DStore(setCurrentHouseAction)

  const houseState = currentHouse
    ? promotion.venta
      ? currentHouse.sellState
      : currentHouse.rentState
    : ''
  const houseStateData = HOUSE_STATE_COLORS[houseState]

  return promotion?.withPrinex && currentHouse ? (
    <Root mobile={mobile}>
      <CloseButton
        onClick={() => {
          setCurrentHouse('')
        }}
      />
      {houseStateData.stateBillboard ? (
        <Title mobile={mobile}>{houseStateData.name}</Title>
      ) : (
        <>
          <Title mobile={mobile}>Portal {currentHouse.portal}</Title>
          <Details mobile={mobile} />
          {houseStateData.showPrice && (
            <Price
              mobile={mobile}
              price={
                promotion.venta ? currentHouse.price : currentHouse.rentPrice
              }
              venta={promotion.venta}
            />
          )}
          {houseStateData.showPlans && <PlanButton mobile={mobile} />}
        </>
      )}
    </Root>
  ) : null
}
export default HouseDetails
