import { styled } from '@mui/material/styles'

import BookButton from './BookButton'
import HouseDetails from './HouseDetails'
import Viewer from './Viewer'
import {
  HOUSE_STATE_COLORS,
  getCurrentHouse,
  getCurrentPromotion
} from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.overlay + 100,
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingBottom: 40,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.main
}))

const PlanView = () => {
  const promotion = useH3DStore(getCurrentPromotion)
  const currentHouse = useH3DStore(getCurrentHouse)
  const houseState = currentHouse
    ? promotion.venta
      ? currentHouse.sellState
      : currentHouse.rentState
    : ''
  const houseStateData = HOUSE_STATE_COLORS[houseState]

  return promotion ? (
    <Root>
      {promotion.withPrinex && houseStateData?.showBook && (
        <BookButton
          src={'images/llaves2.png'}
          title="Reserva tu vivienda"
          venta={promotion.venta}
        />
      )}
      <HouseDetails />
      <Viewer />
    </Root>
  ) : null
}
export default PlanView
