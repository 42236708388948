import { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  MAIN_MODE,
  filterActiveSelector,
  isMobile,
  mainModeSelector,
  setFilterActiveAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import FiltroIcon from '../Icons/FiltroIcon'
import Button from './Button'
import { getCurrentPromotion } from '../../modules/promotionStore'

export const FilterButton = () => {
  const mobile = useH3DStore(isMobile)
  const filterActive = useH3DStore(filterActiveSelector)
  const setFilterActive = useH3DStore(setFilterActiveAction)
  const mainMode = useH3DStore(mainModeSelector)
  const promotion = useH3DStore(getCurrentPromotion)
  const theme = useTheme()

  const handleFilter = useCallback(() => {
    setFilterActive(!filterActive)
  }, [setFilterActive, filterActive])

  // return mainMode !== MAIN_MODE.VIEW_3D ? null : (
  return (
    <Button
      title="Filtrar"
      selected={filterActive}
      mobile={mobile}
      onClick={handleFilter}
      disabled={mainMode !== MAIN_MODE.VIEW_3D || !promotion?.loaded}
    >
      <FiltroIcon
        width="auto"
        height="100%"
        primaryColor={
          !filterActive
            ? theme.palette.primary.contrastText
            : theme.palette.primary.main
        }
        secondaryColor={
          !filterActive
            ? theme.palette.secondary.main
            : theme.palette.primary.main
        }
      />
    </Button>
  )
}
export default FilterButton
